import React, { useContext } from 'react';
import { ThemeContext } from 'providers/ThemeProvider';
import { Container } from 'components/common';
import dev from 'assets/illustrations/rate-my-bistro-login.png';
import { Wrapper, SkillsWrapper, Details, Thumbnail } from './styles';

export const Skills = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <Wrapper id="about">
      <SkillsWrapper as={Container}>
        <Thumbnail>
          <img src={dev} alt="Vote for your favority meal!" />
        </Thumbnail>
        <Details theme={theme}>
          <h1>How do you feel when thrown in at the deep end?</h1>
          <p>
            Is it something that makes you feel uncomfortable? Or do you 
            even fear the unknown path in front of you? 
          </p>
          <p>
            This was basically the question I asked myself some time ago and 
            well... long story short: <br/>
            Rate My Bistro came into life. It is a project where every piece 
            of software is written in a language or framework one of my team 
            mates or me do not already know. 
          </p>
          <p>
            And pheeeew 🤯, it was a tough ride until now and it will keep us
            busy for some more time. However, we felt like pioneers and 
            discovered our passion for so many awesome fields of software
            development.
          </p>
          <p>
            I am looking forward to release the first version
            of my very own Frankenstein's Monster 👾.
          </p>
        </Details>
      </SkillsWrapper>
    </Wrapper>
  );
};
